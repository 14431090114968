@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f0e0f;

  font-family: "Anonymous Pro", "Courier New", Courier, monospace;
}

.mainContainer {
  color: white;
  border-radius: 6px;
  background-color: #1d191b;
  text-align: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  border: 1px solid #353032;

  min-width: 300px;
  width: calc(100% - 50px);
  max-width: 500px;

  .profile {
    margin: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    p {
      font-size: 1.4rem;
    }

    .profilePic {
      border-radius: 6px;
      overflow: hidden;
      width: 60px;
      height: 60px;
      background-size: cover;

      background-image: url("./assets/avatar1.png");
      img {
        width: 100%;
        height: 100%;

        transition: opacity 300ms;
        &:hover {
          opacity: 0;
        }
      }
    }
  }

  .linksContainer {
    padding: 15px;

    a {
      text-decoration: none;

      .linkButton {
        transition: background-color 250ms, color 250ms, padding 250ms;

        background-color: #64233b;
        color: white;
        padding: 20px 30px;

        &.highlighted {
          background-color: #792525;

          &:hover {
            background-color: #db4747;
          }
        }

        &:hover {
          padding-left: 35px;
          background-color: #a1304a;

          span {
            color: #efdbc0;
          }
        }

        span {
          font-size: 1.2rem;
          color: #ea5966;
          font-weight: 700;

          &.highlight {
            color: #eacf59;
            font-size: 1.4rem;
          }
        }
      }

      &:not(:first-child) {
        .linkButton {
          margin-top: 25px;
        }
      }

      &:not(:last-child) {
        .linkButton {
          margin-bottom: 25px;
        }
      }
    }
  }
}
