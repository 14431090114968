@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");
body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #0f0e0f;
  font-family: "Anonymous Pro", "Courier New", Courier, monospace;
}

.mainContainer {
  color: white;
  border-radius: 6px;
  background-color: #1d191b;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 15px;
  border: 1px solid #353032;
  min-width: 300px;
  width: calc(100% - 50px);
  max-width: 500px;
}

.mainContainer .profile {
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainContainer .profile p {
  font-size: 1.4rem;
}

.mainContainer .profile .profilePic {
  border-radius: 6px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  background-size: cover;
  background-image: url("./assets/avatar1.png");
}

.mainContainer .profile .profilePic img {
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.mainContainer .profile .profilePic img:hover {
  opacity: 0;
}

.mainContainer .linksContainer {
  padding: 15px;
}

.mainContainer .linksContainer a {
  text-decoration: none;
}

.mainContainer .linksContainer a .linkButton {
  -webkit-transition: background-color 250ms, color 250ms, padding 250ms;
  transition: background-color 250ms, color 250ms, padding 250ms;
  background-color: #64233b;
  color: white;
  padding: 20px 30px;
}

.mainContainer .linksContainer a .linkButton.highlighted {
  background-color: #792525;
}

.mainContainer .linksContainer a .linkButton.highlighted:hover {
  background-color: #db4747;
}

.mainContainer .linksContainer a .linkButton:hover {
  padding-left: 35px;
  background-color: #a1304a;
}

.mainContainer .linksContainer a .linkButton:hover span {
  color: #efdbc0;
}

.mainContainer .linksContainer a .linkButton span {
  font-size: 1.2rem;
  color: #ea5966;
  font-weight: 700;
}

.mainContainer .linksContainer a .linkButton span.highlight {
  color: #eacf59;
  font-size: 1.4rem;
}

.mainContainer .linksContainer a:not(:first-child) .linkButton {
  margin-top: 25px;
}

.mainContainer .linksContainer a:not(:last-child) .linkButton {
  margin-bottom: 25px;
}
